import React, { useEffect, useState } from "react";
import { PrimeReactProvider } from "primereact/api";
import { Help } from "../components/Help";
import ActiveUserRoute from "./ActiveUserRoute";
// import { isEqual } from 'lodash'
// import { RenderCounter } from "./pages/apps/NCTO/UtilityComponets";

import "../style/primereact-theme.css";
import "../style/App.css";
import "../style/theme.css";
import "/node_modules/primeflex/primeflex.css";
import "/node_modules/bootstrap/dist/css/bootstrap.css";


import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionStatus, InteractionType, InteractionRequiredAuthError } from "@azure/msal-browser";
import { useSelector, useDispatch } from "react-redux";
// import { setUser } from "../utils/redux/loginSlice";
// import { callMsGraph } from "../utils/auth/graph";
import { login_auth } from "../utils/auth/login";

import { createContext } from "react";

// import ClipLoader from "react-spinners/ClipLoader";
// import RingLoader from "react-spinners/RingLoader";

import {
    DataRefresher,
    db,
    GetRemoteVersions,
    //   UpdateLessChangelog,
} from "../utils/DataRefresher";

import { loginRequest } from "../utils/auth/authConfig";
// import { RespWIP, DeptWIP, TeamWIP } from "./pages/reports/wip/WIPreports";
// import { MyWIP } from "./pages/reports/wip/MyWIP";

export const TokenProvider = createContext(null);

export default function App() {
    const [graphData, setGraphData] = useState(null);
    const [showHelp, setShowHelp] = useState(false);
    const { instance, inProgress, accounts } = useMsal();
    const USER = useSelector((state) => state.user);
    const authenticated = USER.authenticated;
    const email = USER.STAFF_EMAIL;
    const bearer = useSelector((state) => state.user.bearer);
    const apiRoot = useSelector(state => state.globals.apiRoot)
    const [apiToken, setApiToken] = useState(null);
    const dispatch = useDispatch();
    // eslint-disable-next-line no-unused-vars
    const [dataRefreshingState, setDataRefreshingState] = useState(false);

    useEffect(() => {
        login_auth(
            apiRoot,
            email,
            graphData, 
            inProgress, 
            instance, 
            authenticated, 
            bearer,
            dispatch);
    }, [inProgress, graphData, instance, authenticated, bearer, apiRoot, email, dispatch]);

    useEffect(() => {
        const accessTokenRequest = {
            scopes: ["user.read"],
            account: accounts[0]
        };
        if (inProgress !== InteractionStatus.None || apiToken ) {
            console.log("Waiting on InteractionStatus and API token");
        } else {
            console.log("Have InteractionStatus and API token");
        if (authenticated && inProgress === InteractionStatus.None && accessTokenRequest.account) {
            instance
                .acquireTokenSilent(accessTokenRequest)
                .then((accessTokenResponse) => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log("Node Environment is currently in: ",process.env.NODE_ENV);
                        console.log("Setting API token now.");
                        console.log(accessTokenResponse.accessToken);
                    }

                    setApiToken(`Bearer ${accessTokenResponse.accessToken}`);
                    ;
                })
                .catch((error) => {
                    if (error instanceof InteractionRequiredAuthError) {
                      instance
                        .acquireTokenRedirect(accessTokenRequest)
                        .then(function (accessTokenResponse) {
                          // Acquire token interactive success
                          let accessToken = accessTokenResponse.accessToken;
                          // Call your API with token
                            setApiToken(`Bearer ${accessToken}`);
                            // setCookie(`Bearer ${accessToken}`);
                        })
                        .catch(function (error) {
                          // Acquire token interactive failure
                          console.log(error);
                        });
                    }
                    console.log(error);
                  });
                // End if block
            }
        if (authenticated && apiToken) {
            
            GetRemoteVersions({
                changeTable: `${apiRoot}/operationdata/changelog`,
                tableHeaders: {
                    authorization: apiToken,
                },
            }).then((resp) => {
                const viewsToLoad = {
                    database: db,
                    tableHeaders: {
                        authorization: apiToken,
                    },
                    tables: [
                        // {
                        //   table: "UNPAID_AR",
                        //   endpoint: `${apiRoot}/api/ar/unpaid/full`,
                        //   remoteversion: resp.UNPAID_AR,
                        // },
                        // {
                        //   table: "OUTSTANDING_WIP",
                        //   endpoint: `${apiRoot}/api/wip/outstanding/full`,
                        //   remoteversion: resp.OUTSTANDING_WIP,
                        // },
                        // {
                        //     table: "ENGAGEMENT",
                        //     endpoint: `${apiRoot}/operationdata/engagements`,
                        //     remoteversion: resp.ENGAGEMENT,
                        // },
                        // {
                        //     table: "STAFF",
                        //     endpoint: `${apiRoot}/operationdata/all-staff`,
                        //     remoteversion: resp.STAFF,
                        // },
                        // {
                        //     table: "JOB_TEMPLATES",
                        //     endpoint: `${apiRoot}/operationdata/job-templates`,
                        //     remoteversion: resp.JOB_TEMPLATES,
                        // },
                        // {
                        //     table: "ENTITY",
                        //     endpoint: `${apiRoot}/operationdata/entities`,
                        //     remoteversion: true,
                        // },
                        // {
                        //     table: "INDUSTRIES",
                        //     endpoint: `${apiRoot}/operationdata/industries`,
                        //     remoteversion: true,
                        // },
                        // {
                        //     table: "OFFICES",
                        //     endpoint: `${apiRoot}/operationdata/offices`,
                        //     remoteversion: true,
                        // },
                        {
                            table: "CONTACTS",
                            endpoint: `${apiRoot}/operationdata/contacts`,
                            remoteversion: true,
                        },
                        // {
                        //     table: "COUNTRIES",
                        //     endpoint: `${apiRoot}/operationdata/countries`,
                        //     remoteversion: resp.COUNTRY,
                        // },
                        // {
                        //     table: "ENTERED_CLIENTS",
                        //     endpoint:
                        //         USER.BOI === 1 || USER.NCTO_APPROVE
                        //             ? `${apiRoot}/api/ncto/allentered`
                        //             : `${apiRoot}/api/ncto/myentered/${USER.STAFFINDEX}`,
                        //     remoteversion: true,
                        // },
                        
                        // {
                        //     table: "JOBS",
                        //     endpoint: `${apiRoot}/api/jobs/trailingquarter`,
                        //     remoteversion: resp.JOBS
                        // },
                        // {
                        //     table: "REQUESTS",
                        //     endpoint: `${apiRoot}/api/people/request/${USER.STAFFINDEX}`,
                        //     remoteversion: true
                        // },
                        // {
                        //     table: "REVIEWS",
                        //     endpoint: `${apiRoot}/api/people/review/self/${USER.STAFFINDEX}`,
                        //     remoteversion: true
                        // },
                    ],
                };
                // console.log(viewsToLoad)
                for (let i = 0; i < viewsToLoad.tables.length; ++i) {
                    let refreshedData = DataRefresher(
                        viewsToLoad.tables[i],
                        viewsToLoad.database,
                        viewsToLoad.tableHeaders
                    );
                    refreshedData.then(() => {
                        console.log("Data Refreshed");
                        if (i === viewsToLoad.tables.length) {
                            setDataRefreshingState(false);
                        }
                    });
                }
            });
        }
    }}, [bearer, authenticated, apiToken, inProgress, accounts, instance, apiRoot]);

    //   useEffect(() => {
    //     const staticToLoad = {
    //       database: db,
    //       tableHeaders: {
    //         authorization: bearer,
    //       },
    //       tables: [
    //         {
    //           table: "STATIC_RESP_AR",
    //           endpoint: `${apiRoot}/api/ar/static/responsible/${USER.STAFFINDEX}`,
    //         },
    //         {
    //           table: "STATIC_DEPT_AR",
    //           endpoint: `${apiRoot}/api/ar/static/dept/${USER.DEPARTMENT}`,
    //         },
    //         {
    //           table: "STATIC_RESP_WIP",
    //           endpoint: `${apiRoot}/api/wip/static/responsible/${USER.STAFFINDEX}`,
    //         },
    //         {
    //           table: "STATIC_DEPT_WIP",
    //           endpoint: `${apiRoot}/api/wip/static/dept/${USER.DEPARTMENT}`,
    //         },
    //         {
    //           table: "STATIC_TEAM_WIP",
    //           endpoint: `${apiRoot}/api/wip/static/team/${USER.HOMEROOM}`,
    //         },
    //       ],
    //     };

    //     for (let i = 0; i < staticToLoad.tables.length; ++i) {
    //       UpdateLessChangelog(
    //         staticToLoad.tables[i],
    //         staticToLoad.database,
    //         staticToLoad.tableHeaders
    //       );
    //     }
    //   });

    // const override = {
    //     display: "block",
    //     margin: "0 auto",
    //     borderColor: "red",
    //     position: "absolute",
    //     top: "65px",
    // };

    return (
        <PrimeReactProvider>
            <TokenProvider.Provider value={apiToken} >
            {/* <Loading dataRefreshingState={dataRefreshingState} override={override} /> */}
                <MsalAuthenticationTemplate
                    interactionType={InteractionType.Redirect}
                    authenticationRequest={loginRequest}
                >
                    <ActiveUserRoute
                        setGraph={setGraphData}
                        inProgress={inProgress}
                        setShowHelp={setShowHelp}
                    />
                </MsalAuthenticationTemplate>
                <Help visible={showHelp} showCallBack={setShowHelp} />
            </TokenProvider.Provider>
        </PrimeReactProvider>
    );
}


