export default function Search(
  event,
  listToFilter,
  setListFunc,
  selector = "label"
) {
  let _filteredList;

  if (!event.query.trim().length) {
    _filteredList = [...listToFilter];
  } else {
    _filteredList = listToFilter.filter((item) => {
      return item[selector].toLowerCase().includes(event.query.toLowerCase());
    });
  }

  setListFunc(_filteredList);
}
