import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { TokenProvider } from "../app/App";



/**
 * Custom hook to fetch data from a specified endpoint and update the state with the fetched data.
 *
 * @param {string} endpoint - The API endpoint to fetch data from.
 * @param {function} setData - The state setter function to update the state with the fetched data.
 *
 * This hook uses the `apiToken` from the `TokenProvider` context and the `apiRoot` from the Redux store.
 * It performs a GET request to the specified endpoint and updates the state with the fetched data.
 * If the request fails, it logs an error message to the console.
 */
export function useFetchData(endpoint, data, setData) {
  const apiToken = useContext(TokenProvider);
  const apiRoot = useSelector(state => state.globals.apiRoot);
  
  useEffect(() => {
    if (!data || data.length === 0) {
      console.log(endpoint);
      const fetchData = async () => {
        if (apiToken && apiRoot) {
          try {
            const response = await fetch(`${apiRoot}${endpoint}`, {
              method: "GET",
              headers: { authorization: apiToken },
            });
            if (!response.ok) {
              throw new Error(`Error: ${response.status} ${response.statusText}`);
            }
            const data = await response.json();
            setData(data);
          } catch (error) {
            console.error("Failed to fetch data:", error);
          }
        }
      };

      fetchData();
    }
  }, [apiToken, apiRoot, endpoint, setData, data]);
}