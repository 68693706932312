import ClipLoader from "react-spinners/ClipLoader";
import { Skeleton } from 'primereact/skeleton';
import Cards from "../visualizations/Cards";
import { UnpaidARChart } from '../visualizations/charts/PieCharts';

import "../../style/static-ar-card.css";


export function StaticMetricView({data, header, label, stylesheet}) {
    // const { staticWIP, setStaticWIP, staticValue } = useContext(StaticWIPContext);
    
    const outstanding = data ? parseFloat(data.outstanding).toFixed(2).toString().replace(/\d(?=(\d{3})+\.)/g, '$&,') : null;
    const current = data ? data.percent_curr : 0;
    const overdue = data ? data.percent_overdue : 0;
    const thirty = data ? data.percent_31_60 : 0;
    const sixty = data ? data.percent_61_90 : 0;
    
    return (
        <section className={`${stylesheet ? stylesheet : "static"}-metric-panel `}>
            {/* <h5>{header}</h5> */}
            <div id="data-area">
                <div id="dollar-metrics">
                    <h6>Outstanding{` ${label}`}</h6>
                    {/* <h3 className="lrg-dollar-amount">{"$"+arData[maintype]}</h3> */}
                    <h3 className="lrg-dollar-amount">{outstanding ? "$"+outstanding : <Skeleton width="100%" height="25px" />}</h3>
                </div>
                <div id="static-ar-chart">
                    <UnpaidARChart chartData={data} label={header}/>
                </div>
                <div id="static-ar-cards">
                    {/* <Cards level={objKey} metric='UNPAID_INVOICE' /> */}
                    <Cards cardValue={current} metric='PERCENT_CURR' color='#5C6FC5'/> 
                    <Cards cardValue={thirty} metric='PERCENT_31_60' color='#95CB77'/>
                    <Cards cardValue={sixty} metric='PERCENT_61_90' color='#F4CA5E'/>
                    <Cards cardValue={overdue} metric='PERCENT_OVERDUE' color='#E66B68'/>
                </div>
            </div>
        </section>
    )
}

export function StaticMetric({data, header, stylesheet, label}) {        
    try {
    if (data) {
        return (
            <section className={`${stylesheet ? stylesheet : "static"}-metric-panel `}>
                {/* <h5>{header}</h5> */}
                <div id="data-area">
                    <div id="dollar-metrics">
                        <h6>Outstanding{` ${label}`}</h6>
                        {/* <h3 className="lrg-dollar-amount">{"$"+arData[maintype]}</h3> */}
                        <h3 className="lrg-dollar-amount">{"$"+data.outstanding.toFixed(2).toString().replace(/\d(?=(\d{3})+\.)/g, '$&,')}</h3>
                    </div>
                    <div id="static-ar-chart">
                        <UnpaidARChart chartData={data} label={header}/>
                    </div>
                    <div id="static-ar-cards">
                        {/* <Cards level={props.objKey} metric='UNPAID_INVOICE' /> */}
                        <Cards metric='PERCENT_CURR' color='#5C6FC5' cardValue={data.percent_curr}/> 
                        <Cards metric='PERCENT_31_60' color='#95CB77' cardValue={data.percent_31_60}/>
                        <Cards metric='PERCENT_61_90' color='#F4CA5E' cardValue={data.percent_61_90}/>
                        <Cards metric='PERCENT_OVERDUE' color='#E66B68' cardValue={data.percent_overdue}/>
                    </div>
                </div>
            </section>
        )
    } else {
        return (
            <section className={`${stylesheet ? stylesheet : "static"}-metric-panel `}>
                <ClipLoader loading={data} />
            </section>
        )
    }
} catch (error) {
    console.error(error);
    return (
        <ClipLoader loading={data} />
    )
}
}

export function StaticValues(dataTable, type) {
    
    const ComputPercentages = (values) => {
        let shape = {
            outstanding:values.outstanding, 
            PERCENT_31_60: (values["31-60 Days"] / numberOfEntries) * 100, 
            PERCENT_61_90: (values["61-90 Days"] / numberOfEntries) * 100, 
            PERCENT_OVERDUE: (values["Overdue WIP"] / numberOfEntries) * 100,  
            PERCENT_CURR: (values["Current"] / numberOfEntries) * 100, 
        }
        return shape
    }
    
    let outstanding;
    let numberOfEntries;
    if (dataTable !== null) {
        numberOfEntries = dataTable.length;
        outstanding = dataTable.reduce((acc, nxt)=>{
        acc.outstanding += nxt[type];
        acc[nxt.AGING_PERIOD] += 1
        return acc;
        }, {outstanding:0, "31-60 Days":0, "61-90 Days":0, "Overdue WIP":0, "Current":0})
        return ComputPercentages(outstanding);
    }
    
    return outstanding;
}
