import { useState } from "react";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../../utils/DataRefresher";
import { Controller } from "react-hook-form";
import "primeicons/primeicons.css";

// import { RenderCounter } from "./UtilityComponets";

import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { Calendar } from "primereact/calendar";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { Card } from "primereact/card";
// import { defaultFY } from "./FlatData";
import { CreateDefualtStartDate } from "./UtilityComponets";

export function ServiceCard({
  field,
  control,
  index,
  lists,
  remove,
  formSetValue,
  getValues,
  watch,
}) {
  const [servPartnerSameClient, setServPartnerIsClient] = useState(
    getValues(`services.${index}.partnerSameAsClient`)
  );
  const [servMngrSameClient, setServMngrSameClient] = useState(
    getValues(`services.${index}.managerSameAsClient`)
  );

  // RenderCounter("Service Card");

  const jobTemplateList = useLiveQuery(async () => {
    const jobs = await db.JOB_TEMPLATES.where("TYPE")
      .equals(field.SERVCODE)
      .sortBy("NAME");
    return jobs;
  });

  return (
    <Card
      key={field.id}
      title={
        <div className="flex justify-content-between">
          <span>{field.SERVNAME} Details</span>
        </div>
      }
      className="w-full my-2"
      pt={{
        content: { className: "flex flex-wrap justify-content-center" },
      }}
    >
      <div className="w-4 px-2">
        <h6>Service Partner</h6>
        <Controller
          name={`services.${index}.servicePartner`}
          rules={{ required: "Must Make Selection" }}
          // defaultValue={fieldData.services[index].value ? fieldData.services[index].value.servicePartner: ""}
          defaultValue={
            servPartnerSameClient ? getValues("partnerSelection") : ""
          }
          control={control}
          render={({ field }) => (
            <Dropdown
              className="w-full"
              key={field.id}
              placeholder={ servPartnerSameClient ? getValues("partnerSelection").EMPLOYEE : "Select Partner"}
              value={
                servPartnerSameClient
                  ? getValues("partnerSelection")
                  : field.value
              }
              options={lists.partnerList}
              optionLabel="EMPLOYEE"
              onChange={(e) => field.onChange(e.value)}
              filter
              showClear
            />
          )}
        />
      </div>
      <div className="w-2 px-1 align-self-end flex flex-wrap justify-content-center">
        <h6 className="w-full text-center">Same As Client Partner?</h6>
        <Controller
          name={`services.${index}.partnerSameAsClient`}
          // defaultValue={value ? value.serviceManager: ""}
          control={control}
          render={({ field }) => (
            <InputSwitch
              className="justify-content-center"
              key={field.id}
              checked={field.value}
              onChange={(e) => {
                field.onChange(e.value);
                setServPartnerIsClient(e.value);
                if (!field.value) {
                  formSetValue(
                    `services.${index}.servicePartner`,
                    getValues("partnerSelection")
                  );
                } else {formSetValue(
                  `services.${index}.servicePartner`,"")}
              }}
              pt={{ button: { className: "w-8" } }}
            />
          )}
        />
      </div>
      <div className="w-4 px-2">
        <h6>Service Manager</h6>
        <Controller
          name={`services.${index}.serviceManager`}
          rules={{ required: "Must Make Selection" }}
          defaultValue={servMngrSameClient ? getValues("managerSelection") : ""}
          control={control}
          render={({ field }) => (
            <Dropdown
              className="w-full"
              key={field.id}
              placeholder={servMngrSameClient ? getValues("managerSelection").EMPLOYEE : "Select Manager"}
              value={
                servMngrSameClient ? getValues("managerSelection") : field.value
              }
              options={lists.managerList}
              optionLabel="EMPLOYEE"
              onChange={(e) => {
                field.onChange(e.value);
              }}
              filter
              showClear
            />
          )}
        />
      </div>
      <div className="w-2 px-1 align-self-end flex flex-wrap justify-content-center">
        <h6 className="w-full text-center">Same As Client Manager?</h6>
        <Controller
          name={`services.${index}.managerSameAsClient`}
          // defaultValue={value ? value.serviceManager: ""}
          control={control}
          render={({ field }) => (
            <InputSwitch
              className="justify-content-center"
              key={field.id}
              checked={field.value}
              onChange={(e) => {
                field.onChange(e.value);
                setServMngrSameClient(e.value);
                if (!field.value) {
                  formSetValue(
                    `services.${index}.serviceManager`,
                    getValues("managerSelection")
                  );
                } else {
                  formSetValue(
                    `services.${index}.serviceManager`, "")
                }
              }}
              pt={{ button: { className: "w-8" } }}
            />
          )}
        />
      </div>

      <Controller
        name={`services.${index}.jobs`}
        // rules={{ required: 'Contact postal code required.'}}
        defaultValue={[]}
        control={control}
        render={({ field }) => (
          <JobTable
            value={field}
            serviceIndex={`services.${index}`}
            jobsArray={jobTemplateList}
            inputData={{
              partnerList: lists.partnerList,
              managerList: lists.managerList,
            }}
            formControl={control}
            formSetValue={formSetValue}
            getValues={getValues}
            watch={watch}
          />
        )}
      />
    </Card>
  );
}

function JobRows({
  jobsArray,
  serviceIndex,
  formControl,
  inputData,
  formSetValue,
  getValues,
  watch,
}) {
  let jobRows = jobsArray
    ? jobsArray.map((item, index) => {
        const jobSelected = watch(`${serviceIndex}.jobs.${index}.jobSelected`);
        const jobEstimate = watch(`${serviceIndex}.jobs.${index}.jobEstimate`);

        formSetValue(`${serviceIndex}.jobs.${index}.jobIndex`, item.JOB_IDX);
        formSetValue(`${serviceIndex}.jobs.${index}.jobInfo`, item);

        return (
          // <div id={index} key={index} className='row-item flex align-items-center justify-content-center w-full'>
          <div
            key={item.JOB_IDX}
            className="row-item flex align-items-center justify-content-center w-full"
            id="job-selection"
          >
            <div className="flex align-items-center w-4 h-3rem truncate job-name">
              <Controller
                name={`${serviceIndex}.jobs.${index}.jobSelected`}
                // rules={{ required: 'Contact postal code required.'}}
                defaultValue={false}
                control={formControl}
                render={({ field }) => (
                  <Checkbox
                    id={field.id}
                    value={field.value}
                    className="mr-1"
                    inputId={item.TMPL_IDX}
                    onChange={(e) => field.onChange(e)}
                    checked={field.value}
                  />
                )}
              />
              <label
                htmlFor={item.TMPL_IDX}
                className="text-bold job-field-label"
              >
                {item.NAME}
              </label>
            </div>
            {jobSelected ? (
              <div className="w-8 flex job-field-selected">
                <div className="w-3">
                  <Controller
                    name={`${serviceIndex}.jobs.${index}.jobPartner`}
                    defaultValue={{}}
                    control={formControl}
                    render={({ field }) => (
                      <Dropdown
                        pt={{ input: { className: "job-field-selected" } }}
                        id={field.id}
                        tooltip={field.value ? field.value.EMPLOYEE : null}
                        value={field.value}
                        disabled={!jobSelected}
                        placeholder="Same as Service Partner"
                        options={inputData.partnerList}
                        optionLabel="EMPLOYEE"
                        onChange={(e) => field.onChange(e)}
                        className="w-full"
                        filter
                      />
                    )}
                  />
                </div>
                <div className="w-3">
                  <Controller
                    name={`${serviceIndex}.jobs.${index}.jobManager`}
                    // rules={{ required: 'Contact postal code required.'}}
                    defaultValue={{}}
                    control={formControl}
                    render={({ field }) => (
                      <Dropdown
                        tooltip={field.value ? field.value.EMPLOYEE : null}
                        id={field.id}
                        value={field.value}
                        disabled={!jobSelected}
                        placeholder="Same as Service Manager"
                        options={inputData.managerList}
                        optionLabel="EMPLOYEE"
                        onChange={(e) => field.onChange(e)}
                        className="w-full"
                        filter
                      />
                    )}
                  />
                </div>
                <div id="estimate">
                  <Controller
                    name={`${serviceIndex}.jobs.${index}.jobEstimate`}
                    // rules={{ required: 'Contact postal code required.'}}
                    // defaultValue={""}
                    control={formControl}
                    render={({ field }) => (
                      <InputNumber
                        id={field.id}
                        value={jobEstimate}
                        onValueChange={(e) => field.onChange(e.value)}
                        placeholder="In U.S. Dollars"
                        disabled={!jobSelected}
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        inputClassName="w-full"
                      />
                    )}
                  />
                </div>
                <div className="calendar" id="calendar">
                  <Controller
                    name={`${serviceIndex}.jobs.${index}.jobPeriodStart`}
                    // rules={{ required: 'Contact postal code required.'}}
                    defaultValue={CreateDefualtStartDate(getValues("FYE"))}
                    control={formControl}
                    render={({ field }) => (
                      <Calendar
                        id={field.id}
                        value={field.value}
                        disabled={!jobSelected}
                        dateFormat="mm/dd/yy"
                        onChange={(e) => field.onChange(e)}
                        // showIcon
                      />
                    )}
                  />
                </div>
                <div className="calendar" id="calendar">
                  <Controller
                    name={`${serviceIndex}.jobs.${index}.jobPeriodEnd`}
                    // rules={{ required: 'Contact postal code required.'}}
                    defaultValue={getValues("FYE")}
                    control={formControl}
                    render={({ field }) => (
                      <Calendar
                        id={field.id}
                        value={field.value}
                        disabled={!jobSelected}
                        dateFormat="mm/dd/yy"
                        onChange={(e) => field.onChange(e)}
                        // showIcon
                      />
                    )}
                  />
                </div>
              </div>
            ) : (
              <div className="w-8 flex">
                <div className="w-3 w-full job-field-unselected flex">
                  <div className="w-10 job-field-unselected-label">
                    Same as Service Partner
                  </div>
                  <i className="pi pi-chevron-down sim-button" />
                </div>
                <div className="w-3 w-full job-field-unselected flex ">
                  <div className="w-10 job-field-unselected-label">
                    Same as Service Manager
                  </div>
                  <i className="pi pi-chevron-down sim-button" />
                </div>
                <div className="w-2 w-full job-field-unselected">
                  In U.S. Dollars
                </div>
                <div className="w-2 w-full job-field-unselected">
                  mm/dd/yyyy
                </div>
                <div className="w-2 w-full job-field-unselected">
                  mm/dd/yyyy
                </div>
              </div>
            )}
          </div>
        );
      })
    : [];

  return jobRows;
}

function JobTable({
  value,
  serviceIndex,
  jobsArray,
  formControl,
  inputData,
  formSetValue,
  getValues,
  watch,
}) {
  return (
    <Card title="Select Jobs" className="job-select-card w-full mt-2">
      <div className="flex justify-content-center job-select-column-names">
        {/* {jobFields} */}
        <div className="w-4">Job Type</div>
        <div className="w-8 flex">
          <div className="w-3 ml-2">Job Partner</div>
          <div className="w-3">Job Manager</div>
          <div className="w-2">Estimate</div>
          <div className="w-2 ">Period Start</div>
          <div className="w-2">Period End</div>
        </div>
      </div>

      <JobRows
        jobsArray={jobsArray}
        serviceIndex={serviceIndex}
        formControl={formControl}
        inputData={inputData}
        formSetValue={formSetValue}
        getValues={getValues}
        watch={watch}
      />
    </Card>
  );
}
