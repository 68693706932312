import { createSlice } from '@reduxjs/toolkit';

const authTokenSlice = createSlice({
    name: 'authToken',
    initialState:"No token has been assigned yet.",
    reducers: {
        addAuthToken(state, action) {
            state = action.payload
        }
    }
});

export const {addAuthToken} = authTokenSlice.actions;
export default authTokenSlice.reducer;