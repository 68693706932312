import GridLoader from "react-spinners/GridLoader";

// Need to add a timeout feature that can be over ridden to give an error message if it takes too long
// Need a way to be able to add an optional message for the loader

export function Loader() {
    console.log("loader is loading")
    return (
        <div className="loader">
        <GridLoader
            // color="#36d7b7"
            color={getComputedStyle(document.documentElement).getPropertyValue('--loader-color')}
            cssOverride={{ margin: "44vh 45vw" }}
            margin={4}
            size={35}
        />
        </div>
    )
}