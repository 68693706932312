import { Button } from "primereact/button";
import { useRef, useEffect } from "react";

export function setAddressValues(srcField, targetField, setValue, getValues) {
  const [address, city, state, postalcode, location] = getValues([
    `${srcField}Address`,
    `${srcField}City`,
    `${srcField}State`,
    `${srcField}PostalCode`,
    `${srcField}Location`,
  ]);
  setValue(`${targetField}Address`, address);
  setValue(`${targetField}City`, city);
  setValue(`${targetField}State`, state);
  setValue(`${targetField}PostalCode`, postalcode);
  setValue(`${targetField}Location`, location);
}

export function Address({ name, street, city, postalCode, state, country }) {
  return (
    <div className="mb-3">
      <ul className="review-unstyled-list">
        <li className="font-bold review-text-lg">{name}</li>
        <li className="font-medium review-text-lg">{street}</li>
        <li className="font-medium review-text-lg">
          {city}, {state} {postalCode} {country}
        </li>
      </ul>
    </div>
  );
}

export function InfoMarker({
  children,
  showCallBack,
  className,
  icon = "pi-info-circle",
}) {
  return (
    <Button
      link
      type="button"
      className={`clear-borders ${className}`}
      onClick={showCallBack}
    >
      {children}
      <i className={`pi ${icon} ml-1`}></i>
    </Button>
  );
}

export function CreateDefualtStartDate(date) {
  const yearInMills = 314064 * 100000;
  if (typeof date === 'string') {
    date = new Date(Date.parse(date))
  }
  const dateStart = new Date(date.getTime() - yearInMills);
  return dateStart;
}

export const MakeExclusiveList = (partnerList, managerList) => {
  if (partnerList && managerList) {
    const joinedList = partnerList.concat(managerList);
    let combineIds = [...new Set(joinedList.map((vals) => vals.STAFFINDEX))];
    const filtered = joinedList.filter((item) => {
      if (combineIds.includes(item.STAFFINDEX)) {
        combineIds.splice(combineIds.indexOf(item.STAFFINDEX), 1);
        return true;
      } else {
        return false;
      }
    });
    return filtered;
  }
};

export function CheckFieldErrors(field, errors, data, item) {
  if (errors[field]) {
    return (
      <span className="pi pi-exclamation-circle field-error-display">
        <span className="ml-1">{errors[field].message}</span>
      </span>
    );
  } else {
    return item ? data[field][item] : data[field];
  }
}

export function FieldError(error) {
  return (
    <span className="pi pi-exclamation-circle field-error-display">
      <span className="ml-1">{error.message}</span>
    </span>
  );
}

export function OutputFieldValue(errors, data, field, subLabel) {
//   console.log(data);
  let output = "";
  if (subLabel) {
    output =
      errors && errors[field][subLabel]
        ? FieldError(errors[field][subLabel])
        : data[field][subLabel]
        ? data[field][subLabel]
        : "";
  } else {
    output =
      errors && errors[field]
        ? FieldError(errors[field])
        : data[field]
        ? data[field]
        : "";
  }
  return output;
}

export function SubtleShadows({ className, children }) {
  return <span className={`${className} subtle-text-shadow`}>{children}</span>;
}

export function SetCountry(fieldRoot, sameAs, getValues, setValue) {
  let country = getValues(`${sameAs}Country`);
  if (country !== getValues(`${fieldRoot}Country`)) {
    setValue(`${fieldRoot}Country`, country);
  }
  return country;
}

export function RenderCounter(name) {
  const renderCount = useRef(0);
  useEffect(() => {
    renderCount.current++;
  });
  console.log(name, renderCount);
}
