import { useRef, useEffect } from "react";
import { Controller, useWatch } from "react-hook-form";
import { states /*countries*/ } from "./FlatData";
import { setAddressValues, InfoMarker, SetCountry } from "./UtilityComponets";

import { InputSwitch } from "primereact/inputswitch";
import { InputMask } from "primereact/inputmask";
import { Panel } from "primereact/panel";
import { Calendar } from "primereact/calendar";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { SelectButton } from "primereact/selectbutton";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { defaultFY } from "./FlatData";

export default function ClientBilling({
  panelClasses,
  panelState,
  onExpand,
  fieldData,
  formControl,
  getValues,
  watch,
  setValue,
  showPriceGuide,
  countries,
}) {
  const ref = useRef(null);

  const billingSameAsClient = useWatch({
    control: formControl,
    name: "billingSameAsClient",
  });
  const billingSameAsContact = useWatch({
    control: formControl,
    name: "billingSameAsContact",
  });

  const originationStateOptions = [
    { label: "Domestic", value: "domestic" },
    { label: "International", value: "international" },
  ];

  useEffect(() => {
    billingSameAsClient &&
      setAddressValues("client", "billing", setValue, getValues);
    billingSameAsContact &&
      setAddressValues("contact", "billing", setValue, getValues);
  }, [billingSameAsClient, billingSameAsContact, getValues, setValue]);

  const contactLocation = watch("contactLocation");
  const clientLocation = watch("clientLocation");

  useEffect(() => {
    if (panelState === true) {
      ref.current.expand();
    } else if (panelState === false) {
      ref.current.collapse();
    }
  }, [panelState]);
  // console.log(fieldData);
  const billingLocation = watch("billingLocation");
  // if (billingLocation === "domestic") {setValue("billingCountry", "United States")}

  const invoiceMethodOptions = [
    { label: "Print & Mail", value: "print" },
    { label: "Email", value: "email" },
  ];

  return (
    <Panel
      header={<span className="ncto-panel-title">Billing Information</span>}
      pt={{ content: { className: panelClasses } }}
      ref={ref}
      toggleable
      onExpand={onExpand}
    >
      <div className="w-2 px-1 align-self-end flex flex-wrap justify-content-center">
        <h6 className="w-full text-center">Client Fiscal Year End</h6>
        <Controller
          name="FYE"
          control={formControl}
          defaultValue={defaultFY.end}
          render={({ field }) => (
            <Calendar
              id={field.name}
              value={field.value}
              dateFormat="mm/dd/yy"
              onChange={(e) => field.onChange(e.value)}
              showIcon
            />
          )}
        />
      </div>
      <div className="w-2 px-1 align-self-end flex flex-wrap justify-content-center">
        <h6 className="w-full text-center">
          <InfoMarker
            showCallBack={() => showPriceGuide(true)}
            className={"price-guide-button"}
          >
            Estimated Fees
          </InfoMarker>
        </h6>
        <Controller
          name="estimatedFeesClientLevel"
          rules={{ required: "Estimated Fees Required." }}
          control={formControl}
          render={({ field }) => (
            <InputNumber
              id={field.name}
              value={field.value}
              onValueChange={(e) => field.onChange(e.value)}
              placeholder="In U.S. Dollars Only"
              minFractionDigits={2}
              maxFractionDigits={2}
              mode="currency"
              currency="USD"
              locale="en-US"
              inputClassName="w-full"
            />
          )}
        />
      </div>

      <div
        id="contact-search"
        className="w-2 px-1 align-self-end flex flex-wrap justify-content-center"
      >
        <h6 className="w-full text-center">Estimated Hours</h6>
        <Controller
          name="estHrsClientLevel"
          rules={{ required: "Estimated Hours Required." }}
          control={formControl}
          render={({ field }) => (
            <InputNumber
              id={field.name}
              // input={field.name}
              value={field.value}
              onValueChange={(e) => field.onChange(e.value)}
              placeholder="Quarter Hour Increments"
              minFractionDigits={2}
              maxFractionDigits={2}
              inputClassName="w-full"
            />
          )}
        />
      </div>

      <div id="email" className="w-4 px-2">
        <h6>Email</h6>
        <Controller
          name="billingEmail"
          rules={{ required: "Billing Email Required." }}
          control={formControl}
          defaultValue={""}
          render={({ field }) => (
            <InputText
              className="w-full"
              id={field.name}
              input={field.name}
              placeholder="Enter Billing Email"
              value={billingSameAsContact
                ? fieldData.contactEmail
                : field.value}
              onChange={(e) => field.onChange(e.target.value)}
            />
          )}
        />
      </div>

      <div className="w-7 px-2">
        <h6>Address</h6>
        <Controller
          name="billingAddress"
          rules={{ required: "Billing Address Required." }}
          control={formControl}
          defaultValue={getValues("billingAddress")}
          render={({ field }) => (
            <InputText
              className="w-full"
              id={field.name}
              placeholder="Enter Address"
              value={
                billingSameAsClient
                  ? getValues("clientAddress")
                  : billingSameAsContact
                  ? getValues("contactAddress")
                  : field.value
              }
              onChange={(e) => {
                field.onChange(e.target.value);
              }}
            />
          )}
        />
      </div>
      <div id="city-selection" className="w-5 m-0 px-2">
        <h6>City</h6>
        <Controller
          name="billingCity"
          rules={{ required: "Billing City Required." }}
          control={formControl}
          defaultValue={""}
          render={({ field }) => (
            <InputText
              className="w-full"
              placeholder="Enter City"
              value={
                billingSameAsClient
                  ? fieldData.clientCity
                  : billingSameAsContact
                  ? fieldData.contactCity
                  : field.value
              }
              onChange={(e) => field.onChange(e.target.value)}
              pt={{ root: { className: "w-full" } }}
            />
          )}
        />
      </div>

      <div id="billing-location" className="w-auto px-1 align-self-end ">
        <Controller
          name="billingLocation"
          // rules={{ required: "Client city required." }}
          defaultValue={
            billingSameAsClient
              ? clientLocation
              : billingSameAsContact
              ? contactLocation
              : billingLocation
          }
          control={formControl}
          render={({ field }) => (
            <SelectButton
              className="justify-content-center"
              id={field.name}
              value={field.value}
              onChange={(e) => {
                !billingSameAsClient & !billingSameAsContact &&
                  field.onChange(e.value);
                // e.value === "domestic" && setValue("billingCountry", "United States")
              }}
              options={originationStateOptions}
            />
          )}
        />
      </div>

      <div className="w-3 m-0 px-3">
        <h6>{billingLocation === "international" ? "Province" : "State"}</h6>
        <Controller
          name="billingState"
          rules={{ required: "Billing State Required." }}
          control={formControl}
          defaultValue={
            billingSameAsClient
              ? fieldData.clientState
              : billingSameAsContact
              ? fieldData.contactState
              : ""
          }
          render={({ field }) => {
            if (billingLocation !== "international") {
              return (
                <Dropdown
                  className="w-full"
                  id={field.name}
                  inputId={field.name}
                  placeholder="Select State"
                  value={
                    billingSameAsClient
                      ? fieldData.clientState
                      : billingSameAsContact
                      ? fieldData.contactState
                      : field.value
                  }
                  options={states}
                  optionLabel="name"
                  onChange={(e) => {
                    field.onChange(e.value);
                  }}
                  filter
                  showClear
                />
              );
            } else {
              return (
                <InputText
                  className="w-full"
                  id={field.name}
                  placeholder="Enter Province"
                  value={
                    billingSameAsClient
                      ? fieldData.clientState
                      : billingSameAsContact
                      ? fieldData.contactState
                      : field.value
                  }
                  onChange={(e) => field.onChange(e.target.value)}
                  pt={{ root: { className: "w-full" } }}
                />
              );
            }
          }}
        />
      </div>
      {billingLocation === "domestic" ? (
        <div className="w-2 px-3">
          <h6>Zip Code</h6>
          <Controller
            name="billingPostalCode"
            rules={{ required: "Billing Postal Code Required." }}
            control={formControl}
            render={({ field }) => (
              <InputMask
                id={field.name}
                className="w-full"
                placeholder="Enter Zip"
                mask="99999"
                value={
                  billingSameAsClient
                    ? fieldData.clientPostalCode
                    : billingSameAsContact
                    ? fieldData.contactPostalCode
                    : field.value
                }
                onChange={(e) => field.onChange(e.value)}
              />
            )}
          />
        </div>
      ) : (
        <div className="w-2 px-3">
          <h6>Zip Code</h6>
          <Controller
            name="billingPostalCode"
            rules={{ required: "Billing Postal Code Required." }}
            control={formControl}
            render={({ field }) => (
              <InputText
                className="w-full"
                id={field.name}
                placeholder="Enter Zip"
                value={
                  billingSameAsClient
                    ? fieldData.clientPostalCode
                    : billingSameAsContact
                    ? fieldData.contactPostalCode
                    : field.value
                }
                // suggestions={cityList}
                onChange={(e) => field.onChange(e.target.value)}
                pt={{ root: { className: "w-full" } }}
              />
            )}
          />
        </div>
      )}
      <div className="w-2 px-1 align-self-end flex flex-wrap justify-content-center">
        <h6 className="w-full text-center">Same As Client?</h6>
        <Controller
          name="billingSameAsClient"
          defaultValue={false}
          control={formControl}
          render={({ field }) => (
            <InputSwitch
              className="justify-content-center"
              id={field.name}
              checked={field.value}
              onChange={(e) => {
                field.onChange(e.value);
                // setSameAsClient(e.value);
                if (e.value) {
                  // setSameAsContact(false);
                  setValue("billingSameAsContact", false);
                  // setAddressValues("client", "billing", setValue, getValues);
                }
              }}
            />
          )}
        />
      </div>
      <div className="w-1 px-1 align-self-end flex flex-wrap justify-content-center">
        <h6 className="w-full text-center">Same As Contact?</h6>
        <Controller
          name="billingSameAsContact"
          defaultValue={false}
          control={formControl}
          render={({ field }) => (
            <InputSwitch
              className="justify-content-center"
              id={field.name}
              checked={field.value}
              onChange={(e) => {
                field.onChange(e.value);
                // setSameAsContact(e.value);
                if (e.value) {
                  // setSameAsClient(false);
                  setValue("billingSameAsClient", false);
                  // setAddressValues("contact", "billing", setValue, getValues);
                  setValue("billingEmail", getValues("contactEmail"));
                }
              }}
            />
          )}
        />
      </div>

      {billingLocation === "international" && (
        <div id="country-selection" className="w-3 m-0 px-3">
          <h6>Country</h6>
          <Controller
            name="billingCountry"
            rules={{ required: "Billing Country Required." }}
            control={formControl}
            render={({ field }) => {
              return (
                <Dropdown
                  className="w-full"
                  id={field.name}
                  inputId={field.name}
                  placeholder="Select Country"
                  value={
                    billingSameAsClient
                      ? SetCountry("billing", "client", getValues, setValue)
                      : billingSameAsContact
                      ? SetCountry("billing", "contact", getValues, setValue)
                      : field.value
                  }
                  options={countries}
                  optionLabel="name"
                  onChange={(e) => {
                    field.onChange(e.value);
                  }}
                  filter
                  showClear
                />
              );
            }}
          />
        </div>
      )}

      <div
        id="invoice-method"
        className="w-3 flex flex-wrap justify-content-center"
      >
        <h6 className="w-full text-center">Invoice Type</h6>
        <Controller
          name="invoiceMethod"
          rules={{ required: "No Invoice Type Selected" }}
          defaultValue={false}
          control={formControl}
          render={({ field }) => (
            <SelectButton
              className="justify-content-center"
              id={field.name}
              value={field.value}
              onChange={(e) => field.onChange(e.value)}
              options={invoiceMethodOptions}
              // pt={{button: {className:'w-8'}}}
            />
          )}
        />
      </div>
      <div className="w-full flex justify-content-end">
        <Button
          className="mr-4"
          type="button"
          label="Back"
          onClick={() => onExpand(1)}
        />
        <Button label="Next" type="button" onClick={() => onExpand(3)} />
      </div>
    </Panel>
  );
}
