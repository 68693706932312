import ReactEChart from 'echarts-for-react';



export function UnpaidARChart ({chartData, label}) {

  let percentCurr = 0;
  let percent31 = 0;
  let percent61 = 0;
  let percentOverdue = 0;
  
    if (chartData) {
      percentCurr = chartData.percent_curr === 0 ? 0 : chartData.percent_curr.toFixed(2);
      percent31 = chartData.percent_31_60.toFixed(2);
      percent61 = chartData.percent_61_90.toFixed(2);
      percentOverdue = chartData.percent_overdue.toFixed(2);
    } 

    console.log(chartData);

    const pieChartOptions = {
        tooltip: {
        trigger: 'item'
        },
        graphic: {
            elements: [{
              type: 'text',
              left: 'center',
              top: 'middle',
              z: 999,
              style: {
                text: label,
                textAlign: 'center',
                fontSize: 26,
              }
            }]
          },
        series: [
        {
            name: 'Unpaid AR',
            type: 'pie',
            radius: ['55%', '95%'],
            avoidLabelOverlap: true,
            startAngle: 180,
            label: {
            show: false,
            position: 'center'
            },
            emphasis: {
                label: {
                    position: 'inside',
                    show: true,
                    fontSize: 20,
                    fontWeight: 'bold',
          
                    formatter(param) {
                        // correct the percentage
                        return param.name + ' (' + param.percent + '%)';
                    },
                    itemStyle: {
                        z: 999,
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                      }
                }
            },
            data: [
            { value: percentCurr, name: 'Current'},
            { value: percent31, name: '31-60 Days', itemStyle: {}},
            { value: percent61, name: '61-90 Days', itemStyle: {}},
            { value: percentOverdue, name: 'Overdue', itemStyle: {}},
            ],
        }
        ]
    }
    return (<ReactEChart option={pieChartOptions} className='upaid-ar-pie-chart' style={{height: '200px', width: '275px'}}/>)  
}
