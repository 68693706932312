import { useState } from "react";
import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export default function ExistingClientsTable({
  existingContacts,
  formSetValue,
}) {
  const [selectedClient, setSelectedClient] = useState(null);
  const [filters, setFilters] = useState({
    NAME: { value: null, matchMode: FilterMatchMode.CONTAINS },
    EMAIL: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });

  const fillContactFields = (contact) => {
    // console.log(contact.data.INDEX);
    formSetValue("contactExisting", contact.data.INDEX);
    formSetValue("contactFirstName", contact.data.FIRST_NAME);
    formSetValue("contactLastName", contact.data.LAST_NAME);
    formSetValue("contactAddress", contact.data.FIRST_NAME);
    formSetValue("contactEmail", contact.data.EMAIL);
    formSetValue("contactPhoneNumber", contact.data.PHONE);
    formSetValue("contactAddress", contact.data.ADDRESS);
    formSetValue("contactCity", contact.data.CITY);
    formSetValue("contactState", contact.data.STATE);
    formSetValue("contactPostalCode", contact.data.POSTAL_CODE);
    formSetValue("contactCode", contact.data.CONTACT_CODE);
  };

  return (
    <DataTable
      value={existingContacts}
      filters={filters}
      onFilter={(e) => setFilters(e.filters)}
      paginator
      rows={10}
      tableStyle={{ minWidth: "50rem", minHeight: "35rem" }}
      stripedRows
      filterDisplay="row"
      size="small"
      selection={selectedClient}
      onSelectionChange={(e) => setSelectedClient(e.value)}
      dataKey="INDEX"
      onRowSelect={fillContactFields}
    >
      <Column selectionMode="single" headerStyle={{ width: "3rem" }}></Column>
      <Column
        field="NAME"
        header="Name"
        filter
        filterField="NAME"
        filterPlaceholder="Search by name"
      ></Column>
      <Column
        field="EMAIL" 
        header="Email"
        filter
        filterField="EMAIL"
        filterPlaceholder="Search by email"
      ></Column>
      <Column field="CITY" header="City"></Column>
      <Column field="STATE" header="State"></Column>
    </DataTable>
  );
}
