import ReactEChart from 'echarts-for-react';
import { useState, useEffect} from "react";

export function RingGuage(props) {

  const [primaryColor, setPrimaryColor] = useState("#00000000");
  const [secondaryColor, setSecondaryColor] = useState("#00000000");
  const get_color = (color_Var) => getComputedStyle(document.documentElement).getPropertyValue(color_Var);

  useEffect(()=>{
    setPrimaryColor(props.colors.primaryColor ? props.colors.primaryColor : get_color('--primary-darken'));
    setSecondaryColor(props.colors.secondaryColor ? props.colors.secondaryColor : get_color('--primary-lighter'));
  },[props.colors.primaryColor, props.colors.secondaryColor]);

  console.log(props)
  
  console.log(primaryColor, secondaryColor)

  const angle = props.angle ? props.angle : 360;
  const min_value = props.min_value ? props.min_value : 0;
  const max_value = props.max_value ? props.max_value : 100;
  const gaugeData = [
      {
        value: props.py.data,
        name: 'Prior Year',
        title: {
          offsetCenter: ['0%', '-40%']
        },
        detail: {
          valueAnimation: true,
          offsetCenter: ['0%', '-20%']
        },    
        itemStyle: {
          color: primaryColor,
        },
      },
      {
        value: props.cy.data,
        name: 'Current Year',
        title: {
          offsetCenter: ['0%', '10%']
        },
        detail: {
          valueAnimation: true,
          offsetCenter: ['0%', '30%']
        },
        itemStyle: {
          color: secondaryColor,
        },
      },
    ];

  const gaugeOptions = {
    title: {
      text: props.label,
      left: 'center',
      bottom: '20%'
    },
    series: [
      {
        type: 'gauge',
        startAngle: angle/2+90,
        endAngle: angle/2*-1+90,
        min: min_value,
        max: max_value,
        // startAngle: 0,
        // endAngle: -360,
        pointer: {
          show: false
        },
        progress: {
          show: true,
          overlap: false,
          roundCap: false,
          clip: true,
          itemStyle: {
            borderWidth: 0.5,
            borderColor: '#464646'
          }
        },
        axisLine: {
          lineStyle: {
            width: 40
          }
        },
        splitLine: {
          show: true,
          distance: -40,
          length: 40
        },
        axisTick: {
          distance: -30,
          length: 20,
          show: true
        },
        axisLabel: {
          show: true,
          distance: -15
        },
        data: gaugeData,
        title: {
          fontSize: 14
        },
        detail: {
          width: 50,
          height: 14,
          fontSize: 14,
          color: 'inherit',
          borderColor: 'inherit',
          borderRadius: 20,
          borderWidth: 1,
          formatter: '{value}%'
        }
      }
    ]
  };
  return (<ReactEChart option={gaugeOptions} className='ring-gauge' style={{height: '300px', width: '300px'}}/>)
}