import { createSlice } from '@reduxjs/toolkit'

export const loginSlice = createSlice({
  name: 'user',
  initialState: {
      authenticated: 'pending',
      bearer: "kVENWeZYVv29CujrgcvpLbTWjxeck4CYuapaR8iOIJykftf9+Fwrh0gyCSnhYtWx5YUEMvbO+/agGgNC5WPGUfosrpGS/YFaMTDdofRct1hykkHjQxo7C3yqN7nwUuvlewjctF6tWmnb7ZaMICs597NEL0HH6tYRskHNJ9zm1zCck0tVLtqRDBLWv94N5uaryxfvwUYD22ZPsFLiYaWdfFG4iClexviU7iPcrTPSNwwSeu4B5kuD1Ah5iV+Faj1ntnCmeNLoNv3g2uod8R8kixvgdA3Uud+O+ywih1WJ9JadyimaJMBBv3wRYPPil59fmOe76v5+E/3hnjDtAnZtug==",
      api_token: "Not set"
  },
  reducers: {
    setAuthentication: (state, auth) => {
        state.authenticated = auth.payload
    },
    setApiAuth: (state, token) => {
      state.api_token = token.payload
    },
    setUser: (state, user) => {
      console.log(user)
        // const newState = {
        //     authenticated: true, 
        //     ...user.payload
        // }

          return user.payload
    },
    clearUser: () => {
      console.log('here')
      console.log(Object.keys(sessionStorage))
      for (let i = 0; i < sessionStorage.length; i++) {
        console.log(sessionStorage.key(i))
        sessionStorage.removeItem(sessionStorage.key(i))
      }
      // sessionStorage.removeItem('persist:root')
      //   return {
      //     authenticated: 'pending',
      //     bearer: "TkQ6kYJB3Prcgq8WBJjv2XJijJM9SNAONNYPMGVgOkHZGbCc1D+rxh8Pa7NMee/1q0zKqZy6WJAjqxuYBMwBxwo13gFiSDG0ZoLWanfB5nLbm/TOlO3QC5HYrrF5A1AQ7/o83zaCKuBA7nSY1UMJjAqHqK7a74YGb71V0PQJT6l4hcEklPJWpD9o0+4KNasdSK7wwM9gtePQWze5prR+Ff1VAM9WpaSlfi+6oYZwE/ZAApt/WJKDW2UWTcuCXNDiLqswAgwz4JSZhMd5KNCSJCPCfNJh7FucVZoD2Xv1FxxJ0h6oPrIH/2BXEoDN3RKLuAMOQhke5CFATxmrjHszxQ=="
      // }
    }
  }
})

// Action creators are generated for each case reducer function
export const { setAuthentication, setUser, clearUser, setApiAuth } = loginSlice.actions

export default loginSlice.reducer