import { useSelector } from "react-redux";
import { createContext, useState } from "react";
import { StaticValues, StaticMetricView} from "../../../components/report_fragments/StaticMetrics";
import OutstandingWIP from "../../../components/report_fragments/OutstandingWIP";

// import { DataViewTable } from "../../../components/visualizations/Tables";

export const StaticWIPContext = createContext(null);

function WIPfragment(filter, titleHead, type) {
  const [staticWIP, setStaticWIP] = useState([]);
  const staticData = StaticValues(staticWIP, "WIPOUTSTANDING");

  return (
    // <StaticWIPContext.Provider value={{staticWIP: staticWIP, setStaticWIP: setStaticWIP, staticWIPvalue: staticWIPvalue}}>
    <div className="content-area">
      {/* <StaticMetric id="StaticWIP" query={static_query} objKey='wipRespStatic' header={titleHead}/> */}
      <StaticMetricView label="WIP" data={staticData} header={titleHead} />
      <OutstandingWIP
        // query={unpaid_query}
        type={type}
        filter={filter}
        header="Responsible"
        onChange={setStaticWIP}
      />
    </div>
    // </ StaticWIPContext.Provider>
  );
}

export function FirmWIP() {
  const user = useSelector((state) => state.user);
  const { TOP_WIP } = user;

  document.title = `Magnify | Client Responsible WIP`;

  if (TOP_WIP === 1) {
    // const static_query = `https://mag-demo.azurewebsites.net/api/wip/static/responsible/${STAFFINDEX}`;
    // const unpaid_query = `https://mag-demo.azurewebsites.net/api/wip/outstanding/responsible/${STAFFINDEX}`;
    // // const static_query = `https://mag-demo.azurewebsites.net/api/ar/static/responsible/27`
    // // const unpaid_query = `https://mag-demo.azurewebsites.net/api/ar/unpaid/responsible/27`

    // return WIPfragment(STAFFINDEX, "My WIP", "responsible");
    return <OutstandingWIP type='all' />
  } else {
    return <div>Sorry, you don't have access to this report</div>;
  }
}

export function RespWIP() {
  const user = useSelector((state) => state.user);
  const { STAFFINDEX, RESPONSIBLE_AR } = user;

  document.title = `Magnify | Client Responsible WIP`;

  if (RESPONSIBLE_AR === 1) {
    // const static_query = `${apiRoot}/wip/static/responsible/${STAFFINDEX}`;
    // const unpaid_query = `${apiRoot}/wip/outstanding/responsible/${STAFFINDEX}`;
    // const static_query = `${apiRoot}/ar/static/responsible/27`
    // const unpaid_query = `${apiRoot}/ar/unpaid/responsible/27`

    return WIPfragment(STAFFINDEX, "My WIP", "responsible");
  } else {
    return <div>Sorry, you don't have access to this report</div>;
  }
}

export function DeptWIP() {
  const user = useSelector((state) => state.user);
  const { DEPT_WIP, DEPARTMENT } = user;

  document.title = `Magnify | ${DEPARTMENT} Department WIP`;

  if (DEPT_WIP === 1) {
    return WIPfragment(DEPARTMENT, `${DEPARTMENT} WIP`, "department");
  } else {
    return <div>Sorry, you don't have access to this report</div>;
  }
}

export function TeamWIP() {
  const user = useSelector((state) => state.user);
  
  const { TEAM_WIP, HOMEROOM } = user;

  document.title = `Magnify | ${HOMEROOM} Team WIP`;

  if (TEAM_WIP === 1) {
    return WIPfragment(HOMEROOM, `Homeroom WIP`, "team");
  } else {
    return <div>Sorry, you don't have access to this report</div>;
  }
}
