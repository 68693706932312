import React from "react";
import ReactDOM from "react-dom/client";
import "./style/index.css";
import App from "./app/App";
import { persistor, store } from "./utils/redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./utils/auth/authConfig";

import "/node_modules/primeflex/primeflex.css";

// Bootstrap components
// import 'bootstrap/dist/css/bootstrap.min.css';

const msalInstance = new PublicClientApplication(msalConfig);
const root = ReactDOM.createRoot(document.getElementById("root"));

/**
 * We recommend wrapping most or all of your components in the MsalProvider component. It's best to render the MsalProvider as close to the root as possible.
 */
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </PersistGate>
  </Provider>
);
