import { ProgressSpinner } from "primereact/progressspinner";

export default function Message({ title, message, spin }) {

    return (
        <div className="message">
            <h5>{title}</h5>
            <p>{message.join('\n')}</p>
            {spin && (
                <ProgressSpinner />
            )}
        </div>
    )
}