import { useState } from "react"
import { db } from "../../utils/DataRefresher";
import { useLiveQuery } from "dexie-react-hooks";
import { DataViewTable } from "../visualizations/Tables"

export default function OutstandingWIP({filter, type, onChange}) {

    const filterID = filter;
    const outstandingWIP = useLiveQuery(
        async ()=> {
            let query;
            let ord;

            const filtConst = (filtId, field) => {
                const filtQuery = new RegExp(filtId );
                return filtQuery.test(field)
            };
            const filt = (test) => filtConst(filterID, test);

            switch (type) {
                case 'responsible':
                    ord = "CLIENT_MANAGER_IDX"
                    query = {"CLIENT_MANAGER_IDX": filterID.toString()};
                    break;
                case 'department':
                    ord = "CLIENT_MANAGER_DEPT"
                    query = {"CLIENT_MANAGER_DEPT": filterID};
                    break;
                case 'team':
                    ord = "PARTNER_HOMEROOM";
                    query = {"PARTNER_HOMEROOM": filterID};
                    break;
                default:
                    query = null
                    break;
            }
            const data = await db.OUTSTANDING_WIP.where(query).toArray();
   
            // Need to abstract this out to a callable function.
            // Doing that to allow for these attributes to be dynamically 
            // loaded from the data actually in the table.

            await db.OUTSTANDING_WIP.orderBy("CLIENT").filter((dept) => filt(dept[ord])).uniqueKeys()
                .then((data) => {setClients(data)});

            await db.OUTSTANDING_WIP.orderBy("CODE").filter((dept) => filt(dept[ord])).uniqueKeys()
                .then((data) => {setCodeList(data)});

            await db.OUTSTANDING_WIP.orderBy("CLIENT_MANAGER").filter((dept) => filt(dept[ord])).uniqueKeys()
                .then((data) => {setClientManagerList(data)});

            await db.OUTSTANDING_WIP.orderBy("CLIENT_PARTNER").filter((dept) => filt(dept[ord])).uniqueKeys()
                .then((data) => {setClientPartnerList(data)});

            await db.OUTSTANDING_WIP.orderBy("OFFICE").uniqueKeys()
                .then((data) => {setOfficeList(data)});

            await db.OUTSTANDING_WIP.orderBy("AGING_PERIOD").uniqueKeys()
                .then((data) => {setAgingPeriod(data)});

            data.OUTSTANDING = data.WIPOUTSTANDING;
            onChange(data);
            return data
        },
        [filterID]
    );

    const [clientList, setClients] = useState([])
    const [clientManagerList, setClientManagerList] = useState([])
    const [officeList, setOfficeList] = useState([]);
    const [clientPartnerList, setClientPartnerList] = useState([]);
    const [agingPeriodList, setAgingPeriod] = useState([]);
    const [codeList, setCodeList] = useState([])

    const lists = {
        CLIENT: clientList,
        CODE: codeList,
        CLIENT_MANAGER: clientManagerList,
        CLIENT_PARTNER: clientPartnerList,
        OFFICE: officeList,
        AGING_PERIOD: agingPeriodList,
        WIPOUTSTANDING: null
    }

    if (!outstandingWIP) {return null};
    // console.log("The Data is: ", outstandingWIP)
    return (
        <section className="data-view-table">
            <DataViewTable 
                data={outstandingWIP} 
                columnList={lists} 
                scrollHeight="56vh" 
                onChange={onChange}
            />
        </section>
    )
    // } else {
    //     return (
    //         <ClipLoader loading={arData} />
    //     )
    // }
}